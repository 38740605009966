import React from 'react';
import { Link } from 'gatsby';
import Container from 'common/source/components/UI/Container';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
// import { UncontrolledTooltip } from 'reactstrap';
import SolarPanelPoly36 from './img/poly-36.png';
import SolarPanelPoly54 from './img/poly-54.png';
import SolarPanelPoly72 from './img/poly-72.png';
import SecLogo from './img/sec.png';
import { PanelsBlockWrapper, SolarPanelsShowRoom } from './solar.styles';

const PanelsPromoPoly = () => {
  return (
    <PanelsBlockWrapper>
      <Container>
        <Box className="row centered">
          <Box className="col info">
            <div className="product-line-title">
              <div className="heading">Energía Solar</div>
              <h3>
                Paneles Solares <br />
                <span>Certificados</span>
                <br />
                Policristalinos
              </h3>
            </div>
            <div className="subheading-img">
              <Image
                className="img-fluid"
                style={{ width: '25%', padding: 0, margin: 0 }}
                src={SecLogo}
                alt="UpSolar Paneles Solares"
              />
            </div>
            <div className="product-line-info">
              <p>
                Gran Variedad de Paneles Solares Policristalinos de 36, 48, 54,
                60 y 72 celdas <strong>(10W-310W)</strong> para uso industrial y
                particular, aplicaciones Off-Grid y On-Grid (conectado a la
                red), con
                <strong> certificación SEC</strong> para generación eléctrica
                residencial
                <strong> (140W-310W)</strong>.
              </p>
            </div>
            <div>
              <Link to="/product/panel-solar-poly-small">Menos de 90W</Link>
              {' | '}
              <Link to="/product/panel-solar-poly-medium">100W a 240W</Link>
              {' | '}
              <Link to="/product/panel-solar-poly-large">250W a 310W</Link>
            </div>
          </Box>
          <Box className="col flex-end">
            <SolarPanelsShowRoom>
              <ul className="sp-showroom-pv">
                <li>
                  <span>
                    {/* <UncontrolledTooltip placement="top" target="sp-pv-poly-small">
                  Menos de <strong>90W</strong>
                </UncontrolledTooltip> */}
                    <Link to="/product/panel-solar-poly-small">
                      <Image
                        className="img-fluid poly-36"
                        src={SolarPanelPoly36}
                        alt="UpSolar Paneles Solares policristalino pequeño"
                        id="sp-pv-poly-small"
                      />
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    {/* <UncontrolledTooltip placement="top" target="sp-pv-poly-medium">
                  De <strong>100W</strong> a <strong>240W</strong>
                </UncontrolledTooltip> */}

                    <Link to="/product/panel-solar-poly-medium">
                      <Image
                        className="img-fluid poly-54"
                        src={SolarPanelPoly54}
                        alt="UpSolar Paneles Solares policristalino mediano"
                        id="sp-pv-poly-medium"
                      />
                    </Link>
                  </span>
                </li>
                <li>
                  <span>
                    {/* <UncontrolledTooltip placement="top" target="sp-pv-poly-lg">
                  De <strong>250W</strong> a <strong>310W</strong>
                </UncontrolledTooltip> */}

                    <Link to="/product/panel-solar-poly-large">
                      <Image
                        className="img-fluid poly-72"
                        src={SolarPanelPoly72}
                        alt="UpSolar Paneles Solares policristalino grande"
                        id="sp-pv-poly-lg"
                      />
                    </Link>
                  </span>
                </li>
              </ul>
            </SolarPanelsShowRoom>
          </Box>
        </Box>
      </Container>
    </PanelsBlockWrapper>
  );
};

export default PanelsPromoPoly;
