import styled from 'styled-components';
import BlueGradient from './img/sp-grad-blue.svg';

const SolarPanelsViewWrapper = styled.div`
  background-image: radial-gradient(circle farthest-side at 110% 0, #596070, #31353e);
  background-repeat: no-repeat;
  padding-top: 5rem;
  padding-bottom: 3rem;
  .container {
    padding-top: 5rem;
    padding-bottom: 3.5rem;
  }
  .row {
    display: flex;
    &.centered {
      align-items: center;
      text-align: center;
    }
    @media only screen and (max-width: 767px) {
      flex-flow: column;
    }
  }
  .col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    @media only screen and (min-width: 767px) {
      width: 50%;
    }
    &.info {
      margin-bottom: 3.5rem;
      @media (max-width: 767px) {
        margin-bottom: 7rem;
      }
    }
    &.flex-end {
      align-self: flex-end;
      @media (max-width: 767px) {
        margin-left: -25px;
        ul {
          margin-left: -25px;
        }
      }
    }
  }
  .product-line-title {
    .heading {
      text-transform: uppercase;
      display: block;
      font-weight: 700;
      font-size: 1rem;
      color: #eee;
      margin-bottom: 0.5rem;
    }
    h2,
    h3 {
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: 300;
      color: #f7f7f7;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 0;
      @media only screen and (max-width: 574px) {
        font-size: 2.2rem;
      }
      span {
        font-weight: 700;
      }
    }
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .product-line-info {
    color: #f7f7f7;
    margin-top: 0;
    padding: 0 5rem;
    @media only screen and (max-width: 768px) {
      padding: 0 3rem;
    }
    @media only screen and (max-width: 574px) {
      padding: 0 1rem;
    }
    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
  a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    transition: 0.1s all;
    &:hover {
      opacity: 0.75;
    }
  }
`;

export const SolarPanelsShowRoom = styled.div`
  /* SHOWROOM PV */
  .sp-showroom-pv {
    list-style-type: none;
    perspective: 5000px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media (min-width: 768px) {
      margin-bottom: 5%;
    }
    @media (max-width: 767px) {
      margin-bottom: 5%;
      margin-top: 85%;
    }
  }

  .sp-showroom-pv li {
    max-width: 50%;
    transform: rotateY(-46deg) rotateX(0deg) rotate(1.5deg);
    position: absolute;
  }

  .sp-showroom-pv li:nth-child(1) {
    left: 10%;
    z-index: 2;
  }
  .sp-showroom-pv li:nth-child(2) {
    right: 20%;
    z-index: 1;
  }
  .sp-showroom-pv li:nth-child(3) {
    right: 0;
  }

  .sp-showroom-pv li img {
    display: inherit;
    animation: none;
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: pointer;
    border-radius: 0.2rem;
    -webkit-box-shadow: -1px 6px 40px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 6px 40px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 6px 40px 0px rgba(0, 0, 0, 0.75);
  }

  .sp-showroom-pv li:hover {
    transform: rotateY(0deg) rotateX(0deg) rotate(0deg) scale(1.05);
    -webkit-transition: all 0.4s ease-out; /* Safari */
    transition: all 0.4s ease-out;
    z-index: 3;
    a {
      opacity: 1;
    }
  }

  .mono-36 {
    width: 60% !important;
  }
  .mono-54 {
    width: 95% !important;
  }
  .mono-72 {
    width: 95% !important;
  }

  .poly-36 {
    width: 55% !important;
  }
  .poly-54 {
    width: 85% !important;
  }
  .poly-72 {
    width: 90% !important;
  }

  .flex-end {
    align-self: flex-end;
    @media (max-width: 767px) {
      margin-left: -25px !important;
    }
  }
`;

export const RegionListWrapper = styled.div`
  padding: 3.5rem 7rem;
  font-size: 0.95rem;
  text-align: center;
  @media (max-width: 767px) {
    padding: 2.5rem 4rem;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li {
      margin-right: 5px;
      display: inline-block;
      i {
        margin-left: 5px;
        color: #fff;
      }
    }
  }
`;

export const PanelsBlockWrapper = styled.div`
  .subheading-img {
    display: flex;
    justify-content: center;
  }
`;

export const PanelViewWrapper = styled.div`
  background: url(${BlueGradient}) no-repeat;
  background-size: cover;
  margin-bottom: -1px;
`;

export const PanelHeroWrapper = styled.div`
  padding-top: 8rem;
  .row {
    margin-top: 3rem;
    display: flex;
    &.centered {
      align-items: center;
      text-align: center;
    }
    @media only screen and (max-width: 767px) {
      flex-flow: column;
    }
  }
  .col {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    @media only screen and (min-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    &.info {
      img {
        max-width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
    &.image {
      text-align: center;
      padding-right: 0;
      max-width: 100%;
      height: auto;
      justify-content: center;
      display: flex;
      img {
        max-width: 100%;
        max-height: 88vh;
      }
    }
  }
  .about-info {
    padding: 0 3rem;
    &.text-center {
      text-align: center;
    }
    h1 {
      text-align: center;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.primary};
    }
    p {
      line-height: 1.5;
      font-size: 1.1rem;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
  .product-line-title {
    .heading {
      text-transform: uppercase;
      display: block;
      font-weight: 700;
      font-size: 1rem;
      color: #eee;
      margin-bottom: 0.5rem;
    }
    h2,
    h3 {
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 300;
      color: #f7f7f7;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 0;
      @media only screen and (max-width: 574px) {
        font-size: 2.2rem;
      }
      span {
        font-weight: 700;
      }
    }
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .product-line-info {
    color: #f7f7f7;
    margin-top: 0;
    padding: 0 5rem;
    @media only screen and (max-width: 768px) {
      padding: 0 3rem;
    }
    @media only screen and (max-width: 574px) {
      padding: 0 1rem;
    }
    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
  a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    transition: 0.1s all;
    &:hover {
      opacity: 0.75;
    }
  }
  .center-cta {
    text-align: center;
    .cta-btn {
      padding: 1rem 0;
      button {
        width: 50%;
        @media only screen and (max-width: 767px) {
          width: 80%;
        }
      }
    }
    .cta-details {
      color: #f7f7f7;
      font-size: 0.8rem;
      line-height: 1.5;
    }
  }
`;

export const PanelTableWrapper = styled.div`
  margin-top: 3rem;
  padding-bottom: 5rem;
  .text-center {
    text-align: center;
  }
  h2,
  h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 400;
    color: #f7f7f7;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0;
    @media only screen and (max-width: 574px) {
      font-size: 1.8rem;
    }
    span {
      font-weight: 700;
    }
  }
  .cert-col {
    display: flex;
    justify-content: center;
  }
  .cert-panel-img {
    margin: 0.2rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
    padding: 0;
  }

  .panels-table {
    display: flex;
    justify-content: center;
    padding: 1rem;
    @media (max-width: 574px) {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
  table {
    color: #eee;
    thead {
      border-bottom: 1px solid;
      text-transform: uppercase;
    }
    tr {
      font-weight: 600;
    }
    th {
      min-width: 85px;
    }
    td {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
`;

export default SolarPanelsViewWrapper;
