import React from 'react';
import { Link } from 'gatsby';

import PanelsPromoMono from './PanelsPromoMono';
import PanelsPromoPoly from './PanelsPromoPoly';
import SolarPanelsViewWrapper, { RegionListWrapper } from './solar.styles';

const SolarPanelsView = ({ regions }) => {
  return (
    <SolarPanelsViewWrapper>
      <PanelsPromoMono />
      <PanelsPromoPoly />
      {regions && (
        <RegionListWrapper>
          <ul>
            {regions.map((region, i) => {
              const { frontmatter } = region;
              return (
                <li key={frontmatter.slug}>
                  <Link to={`/paneles-solares/${frontmatter.slug}`}>{frontmatter.title}</Link>
                  {i + 1 < regions.length && <i>/</i>}
                </li>
              );
            })}
          </ul>
        </RegionListWrapper>
      )}
    </SolarPanelsViewWrapper>
  );
};

export default SolarPanelsView;
