import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Container from 'common/source/components/UI/Container';

import Navbar from 'common/source/containers/SolarNavbar';
import Footer from 'common/source/containers/Footer';
import RegionBanner from 'common/source/containers/RegionBanner';
import CTABanner from 'common/source/containers/CTABanner';

import SolarPanelsView from '../../components/Catalog/Solar/SolarPanels';
import SEO from '../../components/SEO';
import config from '../../../data/SiteConfig';

class RegionPanelsTemplate extends Component {
  render() {
    const { pageContext, data } = this.props;
    const { slug } = pageContext;
    const post = data.region;
    const { frontmatter } = post;
    if (!frontmatter.id) {
      frontmatter.id = slug;
    }
    if (!frontmatter.category_id) {
      frontmatter.category_id = config.postDefaultCategoryID;
    }
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={post} postSEO prefix="paneles-solares" />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar background="blue" />
            <Container>
              <RegionBanner region={post} />
            </Container>
            <SolarPanelsView />
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default RegionPanelsTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query RegionPanelsBySlug($slug: String!) {
    region: markdownRemark(frontmatter: { slug: { eq: $slug }, type: { eq: "REGION-PANELS" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
      }
      frontmatter {
        slug
        title
        region
        PSH
        cover
        date
        category
        tags
      }
    }
  }
`;
