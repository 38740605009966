import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import { Icon } from 'react-icons-kit';
import { iosTimerOutline } from 'react-icons-kit/ionicons/iosTimerOutline';
import SocialShare from '../SocialShare';

import Container from '../../components/UI/Container';
import FeatureBlock from '../../components/FeatureBlock';

import RegionBannerWrapper from './region-banner.style';

const BannerSection = ({
  region,
  allProducts,
  topRow,
  topCol,
  row,
  col,
  titleStyle,
  descriptionStyle,
  textArea,
  imageArea
}) => {
  if (!region || !region.frontmatter) {
    return null;
  }
  const { frontmatter, html } = region;
  const { title, region: regionIndex, PSH, cover } = frontmatter;
  return (
    <RegionBannerWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...textArea}>
            {/* <Heading as="h1" {...titleStyle} content={title} />
            <Text {...descriptionStyle} content={description} />
            <Box>
              <FeatureBlock
                button={(
                  <SocialShare
                    node={region}
                    prefix="paneles-solares"
                    transparent
                    color="textColor"
                  />
)}
              />
            </Box> */}
            <div className="region-heading">
              <h4>Paneles Solares en:</h4>
              <h1>
                {`${title} – `}
                <span>{`${regionIndex} Región`}</span>
              </h1>
            </div>
            <p>
              Revisa nuestra gran variedad de Paneles Solares para uso
              industrial, comercial y doméstica, aplicaciones Off-Grid y On-Grid
              (conectado a la red), con{' '}
              <strong>
                certificación SEC para generación eléctrica residencial en
                {title}.
              </strong>
            </p>
            <div className="region-info">
              <h3>{title}</h3>
              <Icon className="info-icon" icon={iosTimerOutline} size={64} />
              <div className="counter">{PSH}</div>
              <h4 className="h5">
                Radiación Solar <br />
                <br />
                <span>(PSH; Peak Sun Hour)</span>
                <br />
                <span>
                  Fuente:{' '}
                  <a
                    href="http://www.energia.gob.cl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ministerio Energía
                  </a>
                </span>
              </h4>
            </div>
            <div
              className="sp-region-post"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Box>
          <Box
            {...col}
            {...imageArea}
            className="feature__images align-self-center"
          >
            <Image
              className="img-fluid img-no-hover"
              src={cover}
              alt={`upsolar paneles solares en ${title}`}
            />
          </Box>
        </Box>
      </Container>
    </RegionBannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  topRow: PropTypes.object,
  topCol: PropTypes.object,
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    // mt: '25px',
    alignItems: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px'
    //   width: ['100%', '100%', '50%', '50%']
  },
  topRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  topCol: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '50%', '50%']
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '58%']
  },
  imageArea: {
    width: ['100%', '100%', '50%', '50%', '42%'],
    mb: ['40px', '40px', '0', '0', '0'],
    mt: ['40px', '40px', '0', '0', '0']
  },
  titleStyle: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.30'
  },
  descriptionStyle: {
    fontSize: ['14px', '16px', '18px', '18px', '20px'],
    // fontSize: '16px',
    color: 'textColor',
    // lineHeight: '1.75',
    lineHeight: '30px',
    mb: '30px'
  }
};

export default BannerSection;
