import styled from 'styled-components';

const RegionBannerWrapper = styled.section`
  padding-top: 150px;
  padding-bottom: 80px;
  @media (max-width: 990px) {
    padding-top: 150px;
    padding-bottom: 60px;
  }
  @media (max-width: 768px) {
    padding-top: 150px;
    padding-bottom: 60px;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 130px;
    padding-bottom: 50px;
  }
  h1 {
    font-size: 2.25rem;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.headingColor};
    text-transform: uppercase;
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  h3 {
    font-weight: 400;
    font-size: 1.5rem
    color: ${({ theme }) => theme.colors.headingColor};
    margin-bottom: 0.5rem;
  }
  h4 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.labelColor};
    margin: 0;
  }
  p {
    font-size: 1.15rem;
    font-weight: 300;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.textColor};
  }
  .counter {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.1;
    color: ${({ theme }) => theme.colors.textColor};
  }
  .region-info {
    text-align: center;
    margin-bottom: 0.6rem;
    font-size: small;
    .info-icon {
      margin-bottom: 0.5rem;
    }
  }

  .sp-region-post {
    padding: 1.5rem 0;
    ul {
      padding-inline-start: 40px;
      margin-block-start: 1em;
      margin-block-end: 1em;
      li {
        list-style-type: circle;
      }
    }
  }
  .button__wrapper {
    margin-top: 30px;
    display: flex;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        ${'' /* margin-right: 30px; */}
        @media (max-width: 990px) {
          margin-right: 0;
        }
        ${
          '' /* &:hover {
          box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow}
        } */
        }
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      &:hover {
        box-shadow: none !important;
      }
    }
  }
`;

export default RegionBannerWrapper;
